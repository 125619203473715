<template>
  <v-img
    v-bind="$attrs"
    :class="color"
    @click="$emit('click')"
    @load="$emit('load')"
    @error="$emit('error')"
  >
    <template #placeholder>
      <v-row class="fill-height ma-0" align="center" justify="center">
        <v-progress-circular indeterminate color="grey lighten-2"></v-progress-circular>
      </v-row>
    </template>
    <template #default>
      <slot name="default"></slot>
    </template>
  </v-img>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'grey lighten-3'
    }
  }
};
</script>

<style></style>
