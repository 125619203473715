<template>
  <div>
    <CmsBaseImage
      v-if="data && data.image && data.image.url && !error"
      :color="color"
      :src="data.image.url"
      :class="data.class || ''"
      :max-width="data.maxWidth"
      @load="$emit('load')"
      @click="$emit('click')"
      @error="onError"
    />
  </div>
</template>

<script>
import CmsBaseImage from './BaseImage.vue';
import Base from './Base.vue';
export default {
  extends: Base,
  components: {
    CmsBaseImage
  },
  props: {
    color: {
      type: String,
      default: 'grey lighten-3'
    }
  },
  data() {
    return {
      error: false
    };
  },
  methods: {
    onError() {
      this.error = true;
      this.$emit('error');
    }
  }
};
</script>

<style lang="scss">
</style>
